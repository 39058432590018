import "./Contact.scss";
import slide4 from 'Website/assets/images/slide4.png';
import { useTranslation } from "react-i18next";
const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="banner_section right">
                <div>
                    <div className="banner_image_content">
                        <div>
                            <h1 className="title">{t('pages.contactUs.title')}</h1>
                            <p>{t('pages.contactUs.desc')}</p>
                            <div className='contact_box'>
                                <div>
                                    <h5>{t('genericContent.email')}</h5>
                                    <span>{process.env.REACT_APP_CONTACT_MAILID}</span>
                                </div>
                                <div>
                                    <h5>{t('genericContent.phoneNumber')}</h5>
                                    <span>{process.env.REACT_APP_CONTACT_NUMBER}</span>
                                </div>
                                {/* <div>
                                    <h5>{t('genericContent.mailingAddress')}</h5>
                                    <span>ENKRIP LLC 1234</span>
                                    <span>Jakarta -Indonesia</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <picture>
                        <img src={slide4} alt='Contact Us' title='Contact Us' className="lazy-img"/>
                    </picture>
                </div>
            </section>
        </>
    )
}

export default Contact;